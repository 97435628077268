<template>
  <div>
    <contact-list />
  </div>
</template>

<script>
import ContactList from "@/components/ContactList.vue"

export default {
  components: { ContactList }
}
</script>

<style lang="scss" scoped></style>
